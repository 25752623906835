import { QuestionWithOptionalPlaceholder } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { PageTitle } from 'helpers/eventTracking';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { CsQuestion, CsQuestionWithPlaceholder } from 'types/contentStack';
import { Question } from 'types/forms';

type AboutYourPolicyQuestions = {
  coverStartDate: Question & { youngPetExplanatoryText: string };
  start_date_panel: {
    your_cover_will_start_today_label: string;
    your_cover_will_start_label: string;
    choose_a_different_start_date_text: string;
    choose_a_different_start_date_screenreader_text: string;
    hide_calendar_text: string;
    hide_calendar_screenreader_text: string;
  };
  promoCode: QuestionWithOptionalPlaceholder & {
    successPanelText: string;
    errorMessages: {
      unavailable: string;
      notFound: string;
    };
  };
};

type csPetAboutYourPolicyQuestions = {
  csPetAboutYourPolicyQuestions: {
    cover_start_date: CsQuestion & {
      main_details: { young_pet_explanatory_text: string };
    };
    start_date_panel: {
      your_cover_will_start_today_label: string;
      your_cover_will_start_label: string;
      choose_a_different_start_date_text: string;
      choose_a_different_start_date_screenreader_text: string;
      hide_calendar_text: string;
      hide_calendar_screenreader_text: string;
    };
    promo_code: CsQuestionWithPlaceholder & {
      success_panel_text: string;
      error_messages: {
        api_unavailable: string;
        invalid_promo_code: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetAboutYourPolicyQuestions {
      cover_start_date {
        main_details {
          question_text
          explanatory_text
          young_pet_explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      start_date_panel {
        your_cover_will_start_today_label
        your_cover_will_start_label
        choose_a_different_start_date_text
        choose_a_different_start_date_screenreader_text
        hide_calendar_text
        hide_calendar_screenreader_text
      }
      promo_code {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
        error_messages {
          invalid_promo_code
          api_unavailable
        }
        success_panel_text
      }
    }
  }
`;

const useAboutYourPolicyQuestions = (): AboutYourPolicyQuestions => {
  const csQuestions = useStaticQuery<csPetAboutYourPolicyQuestions>(query);

  const {
    processQuestion,
    processQuestionWithOptionalPlaceholder,
  } = useQuestionProcessor(PageTitle.AboutYouAndYourPet);

  const startDatePanel = csQuestions.csPetAboutYourPolicyQuestions.start_date_panel;

  return {
    coverStartDate: {
      ...processQuestion(csQuestions.csPetAboutYourPolicyQuestions.cover_start_date),
      youngPetExplanatoryText:
        csQuestions.csPetAboutYourPolicyQuestions.cover_start_date.main_details
          .young_pet_explanatory_text,
    },
    start_date_panel: {
      your_cover_will_start_today_label: startDatePanel.your_cover_will_start_today_label,
      your_cover_will_start_label: startDatePanel.your_cover_will_start_label,
      choose_a_different_start_date_text:
        startDatePanel.choose_a_different_start_date_text,
      choose_a_different_start_date_screenreader_text:
        startDatePanel.choose_a_different_start_date_screenreader_text,
      hide_calendar_text: startDatePanel.hide_calendar_text,
      hide_calendar_screenreader_text: startDatePanel.hide_calendar_screenreader_text,
    },
    promoCode: {
      ...processQuestionWithOptionalPlaceholder(
        csQuestions.csPetAboutYourPolicyQuestions.promo_code
      ),
      successPanelText:
        csQuestions.csPetAboutYourPolicyQuestions.promo_code.success_panel_text,
      errorMessages: {
        unavailable:
          csQuestions.csPetAboutYourPolicyQuestions.promo_code.error_messages
            .api_unavailable,
        notFound:
          csQuestions.csPetAboutYourPolicyQuestions.promo_code.error_messages
            .invalid_promo_code,
      },
    },
  };
};

export default useAboutYourPolicyQuestions;
