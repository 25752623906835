import { graphql, navigate } from 'gatsby';
import React from 'react';
import LoadQuoteWrapper from 'components/ApiRequestWrapper/LoadQuoteWrapper';
import Layout from 'components/Layout';
import AdditionalQuestionsForm from 'forms/AdditionalQuestionsForm';
import { PageTitle } from 'helpers/eventTracking';
import { usePageTracking } from 'helpers/pageTracking';
import {
  useResetTescoPageLoadTrackingOnRefresh,
  useTescoPageLoadTracking,
  useTescoPageViewTracking,
} from 'helpers/pageTrackingForTesco';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersPlainText } from 'helpers/placeholders/replaceCsPlaceholders';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { CsHero } from 'types/contentStack';

type AdditionalQuestionsProps = {
  data: {
    csPetAggregators: {
      additional_questions: {
        meta_title: string;
        hero: CsHero;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetAggregators {
      additional_questions {
        meta_title
        hero {
          heading
          subheading
        }
      }
    }
  }
`;

const AdditionalQuestions: React.FC<AdditionalQuestionsProps> = ({
  data: {
    csPetAggregators: {
      additional_questions: { meta_title, hero },
    },
  },
}) => {
  const quote = useCurrentQuote();
  usePageTracking(meta_title, !!quote.customerInfo);
  useTescoPageLoadTracking();
  useResetTescoPageLoadTrackingOnRefresh();
  useTescoPageViewTracking();

  const moveNext = (): void => {
    navigate(quoteAndBuyRoutes.loadingQuote, {
      state: { shouldNotInvalidateAssumptions: true },
    });
  };

  const subheading = hero.subheading
    ? replacePlaceholdersPlainText(
        { petNames: quotePlaceholders.petNames },
        {
          ...quote,
          petInfos: quote.petInfos,
        },
        true
      )(hero.subheading)
    : undefined;

  return (
    <LoadQuoteWrapper>
      <Layout
        heading={hero.heading}
        subheading={subheading}
        subheadingIsPii
        metaTitle={meta_title}
        pageTitle={PageTitle.AdditionalQuestions}>
        <AdditionalQuestionsForm moveNext={moveNext} />
      </Layout>
    </LoadQuoteWrapper>
  );
};

export default AdditionalQuestions;
