import DateInput from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import {
  addLeadingZerosToDateValue,
  parseDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import useValidationWithWarnings from '@rsa-digital/evo-shared-components/helpers/forms/useValidationWithWarnings';
import {
  petAgeIsEqualToTwoMonths,
  shouldAggsQuoteShowAdditionalQuestions,
} from 'businessLogic/aggregators';
import {
  getYoungestPetAgeThresholdDate,
  useFirstEligibleStartDateFromToday,
} from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import FormFooter from 'components/FormFooter';
import { usePetDetailsOptions } from 'forms/AboutYourPetsForm/usePetsDetailsOptions';
import SectionHeading from 'forms/SectionHeading';
import { updateItem } from 'helpers/arrayHelper';
import {
  PageTitle,
  trackFieldError,
  trackFormTextFieldFocus,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import getPetIdForInput from 'helpers/getPetId';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { Pet, PetsDetails, usePetsDetails } from 'state/formData/petsDetails';
import { usePolicyDetails } from 'state/formData/policyDetails';
import useAdditionalQuestionsPageQuestions from './questions';
import {
  CoverDateDescription,
  CoverStartSubheading,
  StyledAgeQuestion,
  StyledCoverStartDateQuestion,
} from './styles';
import useAdditionalQuestionsRules from './validation';

type AdditionalQuestionsFormData = {
  csPetAboutYouAndYourPet: {
    next_button_text: string;
  };
  csPetAggregators: {
    additional_questions: {
      cover_start_date_subheading: string;
      cover_start_date_description: string;
    };
  };
};

export const query = graphql`
  query {
    csPetAboutYouAndYourPet {
      next_button_text
    }
    csPetAggregators {
      additional_questions {
        cover_start_date_subheading
        cover_start_date_description
      }
    }
  }
`;

type AdditionalQuestionsFormProps = {
  moveNext: () => void;
};

const AdditionalQuestionsForm: React.FC<AdditionalQuestionsFormProps> = ({
  moveNext,
}) => {
  const {
    csPetAboutYouAndYourPet: { next_button_text },
    csPetAggregators: { additional_questions },
  } = useStaticQuery<AdditionalQuestionsFormData>(query);

  const [policyDetails, updatePolicyDetails] = usePolicyDetails();
  const getAnalyticsDescriptionForInput = (index: number, input: string): string =>
    `Pet ${index} - ${input}`;

  const quote = useCurrentQuote();
  const [firstEligibleStartDate] = useFirstEligibleStartDateFromToday();

  const updateCoverStartDate = (pets: Pet[]): Date => {
    const thresholdDate = getYoungestPetAgeThresholdDate(pets);
    if (thresholdDate && thresholdDate > new Date(policyDetails.coverStartDate)) {
      return firstEligibleStartDate;
    }
    return new Date(policyDetails.coverStartDate);
  };

  const [hasShownAgeQuestion, setHasShownAgeQuestion] = useState(new Set());

  const shouldShowAgeQuestion = (
    dateOfBirth: Date | undefined,
    index: number
  ): boolean => {
    if (petAgeIsEqualToTwoMonths(dateOfBirth) || hasShownAgeQuestion.has(index)) {
      if (!hasShownAgeQuestion.has(index))
        setHasShownAgeQuestion(hasShownAgeQuestion.add(index));
      return true;
    }
    return false;
  };

  const [petDetails, updatePetsDetails] = usePetsDetails();
  const petRules = useAdditionalQuestionsRules();

  // Currently we only route to this page under certain conditions after generating a quote on CTM.
  // CTM only allows for one pet to be added on the quote. If this changes in future the logic will
  // have to be amended
  const questions = useAdditionalQuestionsPageQuestions(petDetails[0]);

  const {
    getError,
    getWarning,
    showValidation,
    validateOnSubmit,
  } = useValidationWithWarnings<{ petDetails: PetsDetails }>(
    { petDetails },
    petRules.errors,
    petRules.warnings,
    trackFieldError
  );

  const [hasShownCalendar, setHasShownCalendar] = useState(new Set());

  const shouldShowCalendar = (index: number): boolean => {
    if (
      (!!getWarning('petDetails', ['petDob', index]) &&
        !getError('petDetails', ['petDob', index])) ||
      hasShownCalendar.has(index)
    ) {
      if (!hasShownCalendar.has(index))
        setHasShownCalendar(hasShownAgeQuestion.add(index));
      return true;
    }
    return false;
  };

  const { petSectionHeading } = usePetDetailsOptions();

  const generateSectionLabel = (petIndex: number): string => {
    const { petName } = petDetails[petIndex];
    return petNameReplacer(petName, petSectionHeading);
  };

  return (
    <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
      {petDetails.map(
        (petDetail, index) =>
          quote.petInfos &&
          shouldAggsQuoteShowAdditionalQuestions(quote) && (
            <div id={`petsDetails[${index}]`} key={petDetail.petDob.toString()}>
              <SectionHeading data-pii-mask heading={generateSectionLabel(index)} />
              {quote.petInfos &&
                (shouldShowAgeQuestion(parseDate(quote.petInfos[index].dob), index) ??
                  undefined) && (
                  <>
                    <StyledAgeQuestion
                      question={questions.petDob}
                      errorText={getError('petDetails', ['petDob', index])}
                      warningText={getWarning('petDetails', ['petDob', index])}>
                      <DateInput
                        id={getPetIdForInput(index)('petDob')}
                        value={petDetail.petDob}
                        onChange={(value) => {
                          updatePetsDetails(
                            updateItem(petDetails, index, { petDob: value })
                          );
                          updatePolicyDetails({
                            coverStartDate: updateCoverStartDate([
                              petDetail,
                            ]).toISOString(),
                          });
                          showValidation('petDetails', ['petDob', index]);
                        }}
                        onBlur={() => {
                          updatePetsDetails(
                            updateItem(petDetails, index, {
                              petDob: addLeadingZerosToDateValue(petDetail.petDob),
                            })
                          );
                          updatePolicyDetails({
                            coverStartDate: updateCoverStartDate([
                              petDetail,
                            ]).toISOString(),
                          });
                          showValidation('petDetails', ['petDob', index]);
                          trackFormTextFieldFocus(
                            getAnalyticsDescriptionForInput(index, 'Pet dob')
                          );
                        }}
                        onFocus={trackFormTextFieldFocus(
                          getAnalyticsDescriptionForInput(index, 'Pet dob')
                        )}
                      />
                    </StyledAgeQuestion>
                    <>
                      {shouldShowCalendar(index) && (
                        <>
                          <CoverStartSubheading>
                            {additional_questions.cover_start_date_subheading}
                          </CoverStartSubheading>
                          {!!getWarning('petDetails', ['petDob', index]) && (
                            <CoverDateDescription>
                              {additional_questions.cover_start_date_description}
                            </CoverDateDescription>
                          )}
                          <StyledCoverStartDateQuestion
                            minDate={firstEligibleStartDate}
                            disableCalendar={!!getError('petDetails', ['petDob', index])}
                            explanatoryText=""
                            questionText=""
                          />
                        </>
                      )}
                    </>
                  </>
                )}
              <FormFooter
                contentColumns={{ desktop: 6, tabletLandscape: 6 }}
                moveNextButton={{
                  text: next_button_text,
                  onClick: () =>
                    trackTextButtonClick(PageTitle.AdditionalQuestions, next_button_text),
                }}
                pageTitle={PageTitle.AdditionalQuestions}
              />
            </div>
          )
      )}
    </form>
  );
};

export default AdditionalQuestionsForm;
