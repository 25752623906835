import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import quoteClient from 'apiHelpers/quoteClient';
import React, { Dispatch, useCallback, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDefaultErrorHandling from 'helpers/errorHandling';
import {
  QUOTE_OPTIONS_SESSION_KEY,
  QUOTE_UUID_SESSION_KEY,
  retrieveData,
} from 'helpers/sessionStorageHelpers';
import useLoadingState from 'helpers/useLoadingState';
import { RootState } from 'state/createStore';
import { ERROR, ErrorAction, ErrorType } from 'state/error/actions';
import {
  QuoteOptions,
  UPDATE_QUOTE_OPTIONS,
  UpdateQuoteOptionsAction,
} from 'state/formData/quoteOptions';
import { useInitialiseQuote } from 'state/quote/loadQuoteHelper';

const LoadQuoteWrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const dispatch = useDispatch<Dispatch<UpdateQuoteOptionsAction>>();
  const initialiseQuote = useInitialiseQuote();
  const dispatchError: Dispatch<ErrorAction> = useDispatch();
  const handleError = useDefaultErrorHandling();

  const currentQuote = useSelector((state: RootState) => state.quote);
  const [hasInitialisedSessionData, setHasInitialisedSessionData] = useReducer(
    () => true,
    !!currentQuote
  );

  const loadQuoteOptions = useCallback((): void => {
    const storedOptions = retrieveData(QUOTE_OPTIONS_SESSION_KEY);

    if (storedOptions) {
      const parsedQuoteOptions: QuoteOptions = JSON.parse(storedOptions);

      dispatch({ type: UPDATE_QUOTE_OPTIONS, update: parsedQuoteOptions });
    }
  }, [dispatch]);

  const loadSavedQuote = useCallback(async (): Promise<void> => {
    const quoteUuid = retrieveData(QUOTE_UUID_SESSION_KEY);
    if (!quoteUuid) {
      console.error('No quote UUID found in session storage');
      dispatchError({ type: ERROR, errorType: ErrorType.SESSION_EXPIRED });
      return;
    }
    try {
      const savedQuote = await quoteClient.getSessionQuote();
      await initialiseQuote(savedQuote);
    } catch (err) {
      const error: Error = err as Error;
      handleError(error);
    }
  }, [dispatchError, handleError, initialiseQuote]);

  const { isLoading: isLoadingQuote, withLoadingState } = useLoadingState();

  useEffect(() => {
    if (!hasInitialisedSessionData) {
      withLoadingState(loadSavedQuote);
      loadQuoteOptions();
      setHasInitialisedSessionData();
    }
  }, [
    currentQuote,
    hasInitialisedSessionData,
    loadSavedQuote,
    loadQuoteOptions,
    withLoadingState,
  ]);

  return (
    <>
      {isLoadingQuote && <LoadingOverlay loadingMessage="Loading quote, please wait" />}
      {children}
    </>
  );
};

export default LoadQuoteWrapper;
