import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';
import CoverStartDateQuestion from 'forms/AboutYourPolicyForm/CoverStartDateQuestion';

export const CoverStartSubheading = styled.p`
  ${fonts.paragraph};
  margin: ${spacing(4)} 0 0;

  ${mediaQuery.tabletLandscape`
    margin: ${spacing(6)} 0 0;
  `}
`;

export const CoverDateDescription = styled.p`
  ${fonts.paragraph};
  margin: ${spacing(1)} 0 0;
`;

export const StyledAgeQuestion = styled(QuestionField)`
  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(4)};
`;

export const StyledCoverStartDateQuestion = styled(CoverStartDateQuestion)`
  margin-top: 0;
  margin-bottom: ${spacing(4)};
  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(6)};
  `}
`;
