import {
  addDaysToDate,
  addYearsToDate,
  dateValueToUtcDate,
  formatNumericDate,
  localDateToUtcDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import {
  applyRuleIf,
  dateValueGreaterThanOrEqualTo,
  dateValueLessThanOrEqualTo,
  dateValueValid,
  dateValueValidDay,
  dateValueValidMonth,
  dateValueValidYear,
  requiredDateValue,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import {
  ValidationErrorAndWarningRules,
  ValidationRule,
} from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { useFirstEligibleStartDateFromToday } from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import { isAfterMinValidDate } from 'helpers/ageHelpers';
import { coverStartDatePlaceholder } from 'helpers/placeholders/coverStartDatePlaceholder';
import { replacePlaceholdersPlainText } from 'helpers/placeholders/replaceCsPlaceholders';
import { petType_DOG } from 'helpers/referenceDataConstants';
import useDisableDateChecks from 'helpers/useDisableDateChecks';
import { Pet, PetsDetails } from 'state/formData/petsDetails';

type CsAdditionalQuestionsErrorMessages = {
  csPetAggregators: {
    additional_questions: {
      young_pet_warning: string;
    };
  };
  csPetAboutYourPetMainQuestions: {
    pet_date_of_birth: {
      error_messages: {
        missing: string;
        date_in_future: string;
        too_young: string;
        invalid_day: string;
        invalid_month: string;
        invalid_date: string;
        year_too_short: string;
      };
      warning_messages: {
        young_pet: string;
        old_pet: string;
        young_pet_additional_question: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetAggregators {
      additional_questions {
        young_pet_warning
      }
    }
    csPetAboutYourPetMainQuestions {
      pet_date_of_birth {
        error_messages {
          missing
          date_in_future
          too_young
          invalid_day
          invalid_month
          invalid_date
          year_too_short
        }
        warning_messages {
          young_pet
          old_pet
        }
      }
    }
  }
`;

export const usePetRules = (): ValidationErrorAndWarningRules<Pet> => {
  const errorMessages = useStaticQuery<CsAdditionalQuestionsErrorMessages>(query);
  const petIsDog = (data: Pet): boolean => data.petType === petType_DOG;
  const coverStartDateReplacer = (date: Date): ((csPlaceHolder: string) => string) =>
    replacePlaceholdersPlainText(coverStartDatePlaceholder, {
      coverStartDate: formatNumericDate(date),
    });
  const firstEligibleDate = useFirstEligibleStartDateFromToday();
  const disableDateChecks = useDisableDateChecks();

  return {
    errors: {
      petDob: [
        requiredDateValue(
          errorMessages.csPetAboutYourPetMainQuestions.pet_date_of_birth.error_messages
            .missing
        ),
        dateValueValidDay(
          errorMessages.csPetAboutYourPetMainQuestions.pet_date_of_birth.error_messages
            .invalid_day
        ),
        dateValueValidMonth(
          errorMessages.csPetAboutYourPetMainQuestions.pet_date_of_birth.error_messages
            .invalid_month
        ),
        dateValueValidYear(
          errorMessages.csPetAboutYourPetMainQuestions.pet_date_of_birth.error_messages
            .year_too_short
        ),
        dateValueValid(
          errorMessages.csPetAboutYourPetMainQuestions.pet_date_of_birth.error_messages
            .invalid_date
        ),
        applyRuleIf(
          () => !disableDateChecks,
          dateValueLessThanOrEqualTo(
            new Date(),
            errorMessages.csPetAboutYourPetMainQuestions.pet_date_of_birth.error_messages
              .date_in_future
          )
        ),
        applyRuleIf(
          () => !disableDateChecks && !firstEligibleDate[1],
          dateValueLessThanOrEqualTo(
            /* Since the API won't accept cover start days greater than 30 days from now (UTC) we need to continue showing
            the error until the local date catches up to UTC. The rule also needs to be consistent with the max cover start date. 
            The pet's dob must be <= the latest cover start date possible - 56 days, since it must be atleast 8wks old when cover starts. 
            As the latest cover start date possible is 30days after today, this is the same as saying dob <= today - 26days */
            addDaysToDate(new Date(), -26),
            errorMessages.csPetAboutYourPetMainQuestions.pet_date_of_birth.error_messages
              .too_young
          )
        ),
        {
          validityCondition: (value) => isAfterMinValidDate(dateValueToUtcDate(value)),
          errorMessage:
            errorMessages.csPetAboutYourPetMainQuestions.pet_date_of_birth.error_messages
              .invalid_date,
        },
      ],
    },
    warnings: {
      petDob: [
        applyRuleIf(
          () => !disableDateChecks && firstEligibleDate[1],
          dateValueLessThanOrEqualTo(
            /* The API compares the cover start date (UTC) to pet DOB (UTC) so we can compare from midnight UTC, rather than
          the current timezone (i.e. BST, if applicable) */
            addDaysToDate(localDateToUtcDate(new Date()), -56),
            coverStartDateReplacer(firstEligibleDate[0])(
              errorMessages.csPetAggregators.additional_questions.young_pet_warning
            )
          )
        ),
        applyRuleIf(
          () => !disableDateChecks,
          dateValueGreaterThanOrEqualTo((data) => {
            const oldPetThresholdAge = petIsDog(data) ? 20 : 25;
            return addYearsToDate(localDateToUtcDate(new Date()), -oldPetThresholdAge);
          }, errorMessages.csPetAboutYourPetMainQuestions.pet_date_of_birth.warning_messages.old_pet)
        ),
      ],
    },
  };
};

/*  We need to validate the rules for each pet in PetsDetails individually. Therefore, we pass in the rules for each property in Pet,
    and apply them for each pet in PetsDetails in turn.
*/
const processPetRules = <T>(
  rules?: ValidationRule<T, Pet>[]
): ValidationRule<T, { petDetails: PetsDetails }>[] | undefined =>
  rules?.map(({ validityCondition, ...rest }) => ({
    ...rest,
    validityCondition: (v, data, index) => validityCondition(v, data.petDetails[index]),
  }));

const useAdditionalQuestionsRules = (): ValidationErrorAndWarningRules<{
  petDetails: PetsDetails;
}> => {
  const petRules = usePetRules();

  return {
    errors: {
      petDetails: {
        petDob: processPetRules(petRules.errors.petDob),
      },
    },
    warnings: {
      petDetails: {
        petDob: processPetRules(petRules.warnings.petDob),
      },
    },
  };
};

export default useAdditionalQuestionsRules;
